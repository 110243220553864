<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="1" class="text-center" v-if="goods.stuffed === true || goods.stripped === true">
        <v-icon color="green darken-4" size="50">mdi-check</v-icon>
      </v-col>
      <v-col :cols="goods.stuffed === true || goods.stripped === true ? 11 : 12" class="text-center">
        <v-hover v-slot="{ hover }" open-delay="160" close-delay="160">
          <v-card
            :elevation="selectable && !disabled && hover ? 6 : (selectable && !disabled ? 4 : 0)"
            rounded="0"
            width="100%"
            :color="selectable && !disabled && hover ? 'grey lighten-2' : 'grey lighten-3'"
            v-on="selectable && !disabled ? { click: onClickCard } : {}"
          >
            <v-card-text class="ma-0 pa-0">
              <v-container fluid>
                <v-row align="center">
                  <v-col v-if="goods.ata && goods.ata !== null" cols="4" :class="`text-left mb-0 pb-0 ${dense ? 'mt-1 pt-1' : ''}`">
                    <v-icon :small="dense" v-if="!hidePositionIcon" :color="!disabled ? (colorByStatus + ' darken-4') : null" class="mr-2">mdi-warehouse</v-icon>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-if="!dense">{{ goods.ata | formatDateTimeFromDate }}</span>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-else>{{ goods.ata | formatDateTimeNoYearFromDate }}</span>
                  </v-col>
                  <v-col v-else cols="4" :class="`text-left mb-0 pb-0 ${dense ? 'mt-1 pt-1' : ''}`">
                    <v-icon :small="dense" v-if="!hidePositionIcon" :color="!disabled ? (colorByStatus + ' darken-4') : null" class="mr-2">mdi-clock-fast</v-icon>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-if="!dense">{{ goods.eta | formatDateTimeFromDate }}</span>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-else>{{ goods.eta | formatDateTimeNoYearFromDate }}</span>
                  </v-col>
                  <v-col cols="8" :class="`${disabled ? 'disabled--text' : ''} text-h5 text-uppercase font-weight-normal text-right mb-0 pb-0 ${dense ? 'mt-1 pt-1' : ''}`"
                    ><span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-light`">{{ goods.goodType }}</span> {{ goods.code }}</v-col
                  >
                </v-row>
                <v-row align="center">
                  <v-col cols="4" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase text-left ${dense ? 'mb-1 pb-1 mt-0 pt-0' : ''}`">{{
                    goods.position && goods.position !== null ? goods.position : "N/A"
                  }}</v-col>
                  <v-col cols="8" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase text-right ${dense ? 'mb-1 pb-1 mt-0 pt-0' : ''}`">
                    {{ goods.description }}
                  </v-col>
                </v-row>
                <v-row align="center" class="grey lighten-4">
                  <v-col cols="4" v-if="goods.parentVisitCode" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium text-left ${dense ? 'py-0' : 'py-1'}`">
                    <v-icon color="primary" class="mr-1 pb-1">mdi-view-week-outline</v-icon> {{ goods.parentVisitCode }}
                  </v-col>
                  <v-col :cols="goods.parentVisitCode ? 8 : 12" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium text-right ${dense ? 'py-0' : 'py-1'}`">
                    {{ goodsQtyWeightLabel !== "" ? goodsQtyWeightLabel : "&nbsp;" }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormatMixins from "../../mixins/FormatsMixins";

export default {
  name: "OrderOpGoodsInfoCard",
  data() {
    return {
    };
  },
  props: {
    goods: {
      type: Object,
      required: true,
    },
    operationStatus: {
      type: String,
      required: false,
    },
    hidePositionIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [FormatMixins],
  emits: ["goodsSelected"],
  computed: {
    colorByStatus() {
      return this.operationStatus && this.operationStatus !== null && this.operationStatus === "E" ? "green" : "blue";
    },
    goodsQtyWeightLabel() {
      var res = "";
      if (this.goods.quantity && this.goods.quantity !== null) {
        res += this.goods.quantity;
        if (this.goods.weight && this.goods.weight !== null) {
          res += " | ";
        }
      }
      if (this.goods.weight && this.goods.weight !== null) {
        res += (Math.round(this.goods.weight * 100) / 100).toFixed(2).replace(".", ",") + " Kg";
      }
      return res;
    },
  },
  methods: {
    onClickCard() {
      this.$emit("goodsSelected", this.goods);
    },
  }
};
</script>

<style></style>

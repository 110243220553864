<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="1" class="text-center" v-if="!dense">
        <v-icon v-if="unit.locked === true" :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" size="40">mdi-lock</v-icon>
        <v-icon v-else :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" size="40">mdi-lock-open-variant</v-icon>
      </v-col>
      <v-col :cols="!dense ? 11 : 12" class="text-center">
        <v-hover v-slot="{ hover }" open-delay="160" close-delay="160">
          <v-card
            :elevation="(selectable && !disabled && hover) ? 6 : (selectable && !disabled ? 4 : 0)"
            rounded="0"
            width="100%"
            :color="colorByStatus + (selectable && !disabled && hover ? ' lighten-3' : ' lighten-4')"
            v-on="selectable && !disabled ? { click: onClickCard } : {}"
          >
            <v-card-text class="ma-0 pa-0">
              <v-container fluid>
                <v-row align="center">
                  <v-col v-if="unit.ata && unit.ata !== null" cols="4" :class="`text-left mb-0 pb-0 ${dense ? 'mt-1 pt-1' : ''}`">
                    <v-icon :small="dense" v-if="!hidePositionIcon" :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" class="mr-2">mdi-warehouse</v-icon>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-if="!dense">{{ unit.ata | formatDateTimeFromDate }}</span>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-else>{{ unit.ata | formatDateTimeNoYearFromDate }}</span>
                  </v-col>
                  <v-col v-else cols="4" :class="`text-left mb-0 pb-0 ${dense ? 'mt-1 pt-1' : ''}`">
                    <v-icon :small="dense" v-if="!hidePositionIcon" :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" class="mr-2">mdi-clock-fast</v-icon>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-if="!dense">{{ unit.eta | formatDateTimeFromDate }}</span>
                    <span :class="`${disabled ? 'disabled--text' : ''} text-subtitle-2 font-weight-light`" v-else>{{ unit.eta | formatDateTimeNoYearFromDate }}</span>
                  </v-col>
                  <v-col cols="8" :class="`${disabled ? 'disabled--text' : ''} text-h5 text-uppercase font-weight-normal text-right mb-0 pb-0 ${dense ? 'mt-1 pt-1' : ''}`">{{ unit.number }}</v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="6" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase text-left ${dense ? 'mb-1 pb-1 mt-0 pt-0' : ''}`">{{
                    unit.position && unit.position !== null ? unit.position : "N/A"
                  }}</v-col>
                  <v-col cols="3" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase text-right ${dense ? 'mb-1 pb-1 mt-0 pt-0' : ''}`" v-if="!dense && unit.weight && unit.weight !== null">
                    {{ unit.weight | numberRounded2WithComma }} Kg
                  </v-col>
                  <v-col :cols="!dense && unit.weight && unit.weight !== null ? 3 : 6" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase text-right ${dense ? 'mb-1 pb-1 mt-0 pt-0' : ''}`">{{
                    unit.type
                  }}</v-col>
                </v-row>
                <v-row align="center" v-if="dense" :class="colorByStatus + ' lighten-5'">
                  <v-col cols="1" class="py-0 px-2">
                    <v-icon v-if="unit.locked === true" :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')">mdi-lock</v-icon>
                    <v-icon v-else :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')">mdi-lock-open-variant</v-icon>
                  </v-col>
                  <v-col cols="11" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium text-right py-0`" v-if="unit.weight && unit.weight !== null">
                    {{ unit.weight | numberRounded2WithComma }} Kg
                  </v-col>
                  <v-col cols="11" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium text-right py-0`" v-else> N/A Kg </v-col>
                </v-row>
                <v-row align="center" v-else-if="sealsVisible" :class="colorByStatus + ' lighten-5'">
                  <v-col cols="4" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium ${dense ? 'py-0' : 'py-1'}`">
                    <v-icon small :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" class="mr-2">mdi-tag-check-outline</v-icon> {{ unit.seal1 }}
                  </v-col>
                  <v-col cols="4" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium ${dense ? 'py-0' : 'py-1'}`">
                    <v-icon small :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" class="mr-2">mdi-tag-check-outline</v-icon> {{ unit.seal2 }}
                  </v-col>
                  <v-col cols="4" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium ${dense ? 'py-0' : 'py-1'}`">
                    <v-icon small :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" class="mr-2">mdi-tag-check-outline</v-icon> {{ unit.seal3 }}
                  </v-col>
                </v-row>
                <v-row align="center" v-else :class="colorByStatus + ' lighten-5'">
                  <v-col cols="12" :class="`${disabled ? 'disabled--text' : ''} text-subtitle-1 text-uppercase font-weight-medium ${dense ? 'py-0' : 'py-1'}`">
                    <v-icon small :color="disabled? 'grey lighten-1' : (colorByStatus + ' darken-4')" class="mr-2">mdi-tag-check-outline</v-icon> {{ tLabel("Nessun sigillo.") }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormatMixins from "../../mixins/FormatsMixins";

export default {
  name: "OrderOpUnitInfoCard",
  data() {
    return {};
  },
  props: {
    unit: {
      type: Object,
      required: true,
    },
    operationStatus: {
      type: String,
      required: false,
    },
    hidePositionIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [FormatMixins],
  emits: ["unitSelected"],
  computed: {
    colorByStatus() {
      return this.operationStatus && this.operationStatus !== null && this.operationStatus === "E" ? "green" : "blue";
    },
    sealsVisible() {
      return (
        this.unit.locked === true &&
        ((this.unit.seal1 && this.unit.seal1 !== null) || (this.unit.seal2 && this.unit.seal2 !== null) || (this.unit.seal3 && this.unit.seal3 !== null))
      );
    },
  },
  methods: {
    onClickCard() {
      this.$emit("unitSelected", this.unit);
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-container fluid class="mb-3">
      <v-row align="center">
        <v-col cols="12" class="mb-0 pb-0">
          <v-card elevation="2" rounded="0" class="mb-1" color="grey lighten-4">
            <v-card-text>
              <v-row>
                <v-col cols="10" class="text-h5 font-weight-bold blue--text">
                  {{ order.reference }} <span class="text-subtitle-1 font-weight-light pl-4">{{ order.extCode }}</span>
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-btn outlined small color="error" :title="tLabel('Elimina ordine di stuffing')" @click="onClickDeleteOrder">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                  &nbsp;
                  <v-btn outlined small color="primary" :title="tLabel('Modifica ordine di stuffing')" @click="goToEditOrder">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" v-if="order.operations" class="mt-0 pt-0">
          <div v-for="op in order.operations" :key="op.id">
            <StuffingOperationCard :operation="op" clickable :backAttrs="backAttrs" />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteOrderDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Elimina ordine") }}</v-card-title>
        <v-card-text class="text-h6 py-2"> Eliminare l'ordine di stuffing {{ order.reference }}/{{ order.extCode }} ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="deleteOrderDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="deleteOrder"><v-icon>mdi-delete</v-icon>{{ tLabel("Elimina") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ToastMixin from "../../mixins/ToastMixin";
import StuffingOperationCard from "@/components/yard/StuffingOperationCard";

export default {
  name: "StuffingOrderCard",
  data() {
    return {
      deleteOrderDialogVisible: false,
    };
  },
  mixins: [ToastMixin],
  components: { StuffingOperationCard },
  props: {
    order: {
      type: Object,
      required: true,
    },
    backAttrs:{
      type: Object,
      default: null,
    }
  },
  methods: {
    onClickDeleteOrder() {
      this.deleteOrderDialogVisible = true;
    },
    deleteOrder() {
      console.log("TODO delete order", this.order);
      this.showSuccess("TODO Ordine eliminato correttamente.");
      this.deleteOrderDialogVisible = false;
    },
    goToEditOrder() {
      this.$router.push({
        name: "EditStuffingOrder",
        params: {
          orderId: this.order.id,
          attrs: this.backAttrs,
        },
      });
    },
  },
};
</script>

<style></style>

<template>
  <v-hover v-slot="{ hover }" open-delay="50">
    <v-card :elevation="hover ? 6 : 2" rounded="0" class="mb-1" :color="hover ? 'grey lighten-5' : null" v-on="clickable ? { click: goToOperationDetail } : {}">
      <v-card-text>
        <v-row align="center">
          <v-col cols="5" class="d-flex flex-row align-center justify-center">
            <v-card
              elevation="0"
              rounded="0"
              :color="operation.unit && operation.unit !== null && operation.unit.locked === true ? 'green lighten-5' : 'grey lighten-4'"
              width="90%"             
              v-if="operation.goods && operation.goods !== null && operation.goods.length > 0"
            >
              <v-card-text>
                <v-container fluid>
                  <v-row align="center">
                    <v-col cols="6" class="pa-0 ma-0">
                      <v-container fluid>
                        <v-row align="center">
                          <v-col cols="6" class="text-right">
                            <v-icon color="grey" size="50">mdi-dolly</v-icon>
                          </v-col>
                          <v-col cols="6" class="text-h5 font-weight-light text-left">
                            {{ stuffedGoodsCount(operation.goods) }}/{{ operation.goods && operation.goods !== null ? operation.goods.length : 0 }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0">
                      <v-container fluid>
                        <ul>
                          <v-row align="center" v-for="goo in toGoodsDescriptions(operation.goods)" :key="goo">
                            <v-col cols="12" class="text-body-2 text-uppercase text-left py-0 my-0">
                              <li>{{ goo }}</li>
                            </v-col>
                          </v-row>
                        </ul>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            :cols="removeEnabled ? 1 : 2"
            v-if="operation.goods && operation.goods !== null && operation.goods.length > 0"
            class="text-center"
          >
            <v-icon size="60" v-if="operation.unit && operation.unit !== null && operation.unit.locked === true" color="green">mdi-check</v-icon>
            <v-icon size="60" v-else>mdi-chevron-right</v-icon>
          </v-col>
          <v-col :cols="removeEnabled ? 1 : 2" v-else class="text-center"></v-col>

          <v-col cols="5" class="text-center" v-if="operation.unit && operation.unit !== null">
            <v-container fluid>
              <v-row align="center">
                
                <v-col cols="10" class="text-center">
                  <v-card elevation="0" rounded="0" style="border: 3px solid grey" width="100%" :color="operation.unit.locked === true ? 'green lighten-3' : 'blue lighten-3'">
                    <v-card-text class="ma-0 pa-0">
                      <v-container fluid>
                        <v-row align="center">
                          <v-col v-if="operation.unit.ata && operation.unit.ata !== null" cols="6" class="text-subtitle-2 text-uppercase text-left mb-0 pb-0">
                            <v-icon color="secondary">mdi-warehouse</v-icon>
                          </v-col>
                          <v-col v-else cols="6" class="text-subtitle-2 text-uppercase text-left mb-0 pb-0">
                            {{ operation.unit.eta | formatDateTimeFromDate }} <v-icon color="secondary" class="ml-2">mdi-clock-fast</v-icon>
                          </v-col>
                          <v-col cols="6" class="text-h5 text-uppercase font-weight-light text-right mb-0 pb-0">{{ operation.unit.number }}</v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="6" class="text-subtitle-1 text-uppercase text-left">{{
                            operation.unit.position && operation.unit.position !== null ? operation.unit.position : "N/A"
                          }}</v-col>
                          <v-col cols="6" class="text-subtitle-1 text-uppercase text-right">{{ operation.unit.type }}</v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-icon v-if="operation.unit && operation.unit !== null && operation.unit.locked === true" color="green" size="40">mdi-lock</v-icon>
                  <v-icon v-else color="blue darken-4" size="40">mdi-lock-open-variant</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="5" v-else class="text-center">
            <v-icon color="grey lighten-2" size="70">mdi-cancel</v-icon>
          </v-col>
          <v-col v-if="removeEnabled" cols="1" class="text-center">
            <v-btn height="43" width="43" color="red lighten-3" @click.stop="removeOperation"><v-icon large>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import FormatMixins from "../../mixins/FormatsMixins";

export default {
  name: "StuffingOperationCard",
  data() {
    return {};
  },
  props: {
    operation: {
      type: Object,
      required: true,
    },
    removeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    backAttrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatMixins],
  emits: ["operationRemoving"],
  methods: {
    goToOperationDetail() {
      this.$router.push({
        name: "StuffingOperation",
        params: {
          operationId: this.operation.id,
          attrs: this.backAttrs,
        },
      });
    },
    removeOperation() {
      this.$emit("operationRemoving", this.operation);
    },
    stuffedGoodsCount(goods) {
      if (goods && goods !== null) {
        return goods.filter((g) => g.stuffed === true).length;
      }
      return 0;
    },
    toGoodsDescriptions(goods) {
      var goodsDescr = [];
      if (goods && goods !== null) {
        goods.forEach((goo) => {
          var valueToAdd = goo.description && goo.description !== null && goo.description !== "" ? goo.description : goo.code;
          if (!goodsDescr.includes(valueToAdd)) {
            goodsDescr.push(valueToAdd);
          }
        });
        if (goodsDescr.length > 3) {
          goodsDescr = goodsDescr.slice(0, 3);
          goodsDescr.push("...");
        }
      }
      return goodsDescr;
    },
  },
};
</script>

<style></style>
